import "./tracking.scss";
import React, { useEffect, useState } from "react";
import { Card, Col, Row, Navbar, Container } from "react-bootstrap";
import { useLocation } from "react-router";

import { getOrderStatus } from "../../api/orders";
import { ORDER_STATUSES } from "../../config/consts/orders";

import dateParser from "../utils/dateParser";

import logo from "../../assets/img/logo-copy.svg";

import PageSpinner from "../common/PageSpinner";
import storeIcon from "../../assets/img/home.png";
import assemblyIcon from "../../assets/img/box-icon.png";
import deliveryIcon from "../../assets/img/card-icon.png";

import instagramIcon from "../../assets/img/instagram-icon.png";
import backgroundFooter from "../../assets/img/footer.png";
import linkedinIcon from "../../assets/img/linkedl-icon.png";
import docIcon from "../../assets/img/Iconos-13.png";
import checkIcon from "../../assets/img/Iconos-16.png";
import carIcon from "../../assets/img/Iconos-15.png";
import boxIcon from "../../assets/img/Iconos-14.png";

const ConsumerTracking = () => {
  const location = useLocation();

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState({ color: "", message: "" });
  const [orderStatus, setOrderStatus] = useState([]);
  const [idOrder, setIdOrder] = useState(null);

  useEffect(() => {
    const fetchOrderStatus = async () => {
      const searchParams = new URLSearchParams(location.search);
      const email = searchParams.get("email");
      const idOrder = parseInt(searchParams.get("id_order"), 10);

      if (!email || isNaN(idOrder)) {
        setError({
          color: "danger",
          message: "Parámetros de búsqueda inválidos.",
        });
        return;
      }

      setIdOrder(idOrder);
      setIsLoading(true);

      try {
        const userEmailParsed = email.toLowerCase().trim()
				const response = await getOrderStatus({ email: userEmailParsed, idOrder })
        setOrderStatus(response?.data?.data || []);
      } catch (err) {
        setError({
          color: "danger",
          message: "Ha ocurrido un problema. Vuelve a intentarlo más tarde.",
        });
      } finally {
        setIsLoading(false);
      }
    };
    fetchOrderStatus();
  }, [location]);

  const isToAssemble = orderStatus?.find(
    (status) => status?.id_status_order === ORDER_STATUSES.TO_ASSEMBLE
  );
  const toAssemble = isToAssemble ? true : "";

  const isAssembled = orderStatus?.find(
    (status) =>
      status?.id_status_order === ORDER_STATUSES.ASSEMBLED ||
      status?.id_status_order === ORDER_STATUSES.READY_TO_BE_DELIVERED
  );
  const assembled = isAssembled ? true : "";

  const isInRouteToConsumer = orderStatus?.find(
    (status) =>
      status?.id_status_order === ORDER_STATUSES.IN_ROUTE_TO_CONSUMER ||
      status?.id_status_order ===
        ORDER_STATUSES.IN_ROUTE_TO_CONSUMER_EXTERNAL_COURIER
  );
  const inRouteToConsumer = isInRouteToConsumer ? true : "";

  const isDeliveredToConsumer = orderStatus?.find(
    (status) =>
      status?.id_status_order === ORDER_STATUSES.DELIVERED_TO_CONSUMER ||
      status?.id_status_order ===
        ORDER_STATUSES.DELIVERED_TO_CONSUMER_BY_EXTERNAL_COURIER
  );
  const deliveredToConsumer = isDeliveredToConsumer ? true : "";

  const isToReturnToWarecloud = orderStatus?.find(
    (status) =>
      status?.id_status_order === ORDER_STATUSES.TO_RETURN_TO_PICKUP_POINT ||
      status?.id_status_order === ORDER_STATUSES.TO_RETURN_TO_NO_PICKUP_POINT
  );
  const toReturnToWarecloud = isToReturnToWarecloud ? true : "";

  if (isLoading) {
    return <PageSpinner />;
  } else {
    return (
      <div>
        <Navbar bg="primary" variant="dark">
          <Container>
            <Navbar.Brand href="#home">
              <img
                src={logo}
                width="150"
                height="30"
                className="d-inline-block align-top"
                alt="Wareclouds logo"
              />
            </Navbar.Brand>
          </Container>
        </Navbar>

        <Container className="mt-5">
          <Row className="justify-content-center text-left">
            <h3 className="layout-text mt-2">
              TU PEDIDO ESTÁ CADA VEZ MÁS CERCA
            </h3>
          </Row>
          <Row className="container">
            <Col md={2}></Col>
            <Col md={8}>
              <Row className="justify-content-center mt-5">
                <Card className="order-info-card p-4">
                  <h4 className="text-left layout-text-sub">
                    INFORMACIÓN DE TU PEDIDO
                  </h4>
                  <Row className="mt-3">
                    <Col>
                      <p className="text-sub">Número de pedido</p>
                      <p className="text-sub-label">{idOrder}</p>
                    </Col>
                    <Col>
                      <p className="text-sub">Marca</p>
                      <p className="text-sub-label">
                        {orderStatus[0]?.ecommerce_name || "No disponible"}
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <p className="text-sub">Nombre</p>
                      <p className="text-sub-label">
                        {orderStatus[0]?.first_name || "No disponible"}{" "}
                        {orderStatus[0]?.last_name || ""}
                      </p>
                    </Col>
                    <Col>
                      <p className="text-sub">Email</p>
                      <p className="text-sub-label">
                        {orderStatus[0]?.email || "No disponible"}
                      </p>
                    </Col>
                  </Row>
                </Card>
              </Row>
            </Col>
            <Col md={2}></Col>
            <div className="container tracking-steps mt-5">
              <div className={toAssemble ? "step" : "step-g"}>
                <div
                  className={`${
                    toAssemble ? "circle" : "circle-g"
                  } text-center`}
                >
                  <img
                    src={docIcon}
                    alt="process"
                    className="tracking-card__images--processingOrder"
                  />
                </div>
                <div className="step-text">
                  <div
                    className={
                      toAssemble ? "text-category" : "text-category-inactive"
                    }
                  >
                    Compra recibida
                  </div>
                  {toAssemble ? (
                    <div
                      className={
                        toAssemble
                          ? "text-category-sub"
                          : "text-category-sub-inactive"
                      }
                    >
                      {dateParser(isToAssemble?.updated_at)}
                    </div>
                  ) : (
                    <div className="void-sub"></div>
                  )}
                </div>
              </div>
              <div className={assembled ? "step" : "step-g"}>
                <div
                  className={`${assembled ? "circle" : "circle-g"} text-center`}
                >
                  <img
                    src={boxIcon}
                    alt="process"
                    className="tracking-card__images--processingOrder"
                  />
                </div>
                <div className="step-text">
                  <div
                    className={
                      assembled ? "text-category" : "text-category-inactive"
                    }
                  >
                    Pedido armado
                  </div>
                  {assembled ? (
                    <div
                      className={
                        assembled
                          ? "text-category-sub"
                          : "text-category-sub-inactive"
                      }
                    >
                      {dateParser(isAssembled?.updated_at)}
                    </div>
                  ) : (
                    <div className="void-sub"></div>
                  )}
                </div>
              </div>
              {isToReturnToWarecloud ? (
                <div className={isToReturnToWarecloud ? "step" : "step-g"}>
                  <div
                    className={`${
                      inRouteToConsumer ? "circle" : "circle-return"
                    } text-center`}
                  >
                    <img
                      src={carIcon}
                      alt="process"
                      className="tracking-card__images--processingOrder"
                    />
                  </div>
                  <div className="step-text">
                    <div
                      className={
                        toReturnToWarecloud
                          ? "text-category-red"
                          : "text-category-inactive"
                      }
                    >
                      Devuelto a bodega
                    </div>
                    {toReturnToWarecloud ? (
                      <div
                        className={
                          toReturnToWarecloud
                            ? "text-category-red-sub"
                            : "text-category-sub-inactive"
                        }
                      >
                        {dateParser(isToReturnToWarecloud?.updated_at)}
                      </div>
                    ) : (
                      <div className="void-sub"></div>
                    )}
                  </div>
                </div>
              ) : (
                <div className={inRouteToConsumer ? "step" : "step-g"}>
                  <div
                    className={`${
                      inRouteToConsumer ? "circle" : "circle-g"
                    } text-center`}
                  >
                    <img
                      src={carIcon}
                      alt="process"
                      className="tracking-card__images--processingOrder"
                    />
                  </div>
                  <div className="step-text">
                    <div
                      className={
                        inRouteToConsumer
                          ? "text-category"
                          : "text-category-inactive"
                      }
                    >
                      Pedido en camino
                    </div>
                    {inRouteToConsumer ? (
                      <div
                        className={
                          inRouteToConsumer
                            ? "text-category-sub"
                            : "text-category-sub-inactive"
                        }
                      >
                        {dateParser(isInRouteToConsumer?.updated_at)}
                      </div>
                    ) : (
                      <div className="void-sub"></div>
                    )}
                  </div>
                </div>
              )}
              <div className={deliveredToConsumer ? "step" : "step-g"}>
                <div
                  className={`${
                    deliveredToConsumer ? "circle" : "circle-g"
                  } text-center`}
                >
                  <img
                    src={checkIcon}
                    alt="process"
                    className="tracking-card__images--processingOrder"
                  />
                </div>
                <div className="step-text">
                  <div
                    className={
                      deliveredToConsumer
                        ? "text-category"
                        : "text-category-inactive"
                    }
                  >
                    Pedido entregado
                  </div>
                  {deliveredToConsumer ? (
                    <div
                      className={
                        deliveredToConsumer
                          ? "text-category-sub"
                          : "text-category-sub-inactive"
                      }
                    >
                      {dateParser(isDeliveredToConsumer?.updated_at)}
                    </div>
                  ) : (
                    <div className="void-sub"></div>
                  )}
                </div>
              </div>
            </div>
          </Row>

          <Row className="text-center">
            {error.message && (
              <p className={`text-${error.color} mt-3`}>{error.message}</p>
            )}
          </Row>
          <Row className="justify-content-center mt-5">
            <h3 className="layout-text">¿Problemas con tu pedido?</h3>
            <h5 className="layout-sub-text">
              Si tienes algún problema con tu pedido puedes comunicarte a través
              de nuestro chat de soporte para que alguien de nuestro equipo te
              ayude y resuelva todas tus dudas.
            </h5>
            <a
              href="https://docs.google.com/forms/d/e/1FAIpQLSexSci95LDpAwSXLO0BiFUJ82FjOCvY16m3jgSXIaieyrrllg/viewform"
              target="_blank"
              rel="noopener noreferrer"
              className="referral-button mt-5"
            >
              NECESITO AYUDA
            </a>
          </Row>
          <Row className="justify-content-center mt-5">
            <h3 className="layout-text">
              ¿Te gustó la experiencia Wareclouds? ¡Refiérenos!
            </h3>
            <h5 className="layout-sub-text text-left">
              Te invitamos a referir a un amigo que tenga una marca que quiera
              ser parte de la revolución logística y tener envíos rápidos y el
              mejor servicio al cliente.
            </h5>
            <a
              href="https://wareclouds.lpages.co/referidos/"
              target="_blank"
              className="referral-button mt-5"
              rel="noopener noreferrer"
            >
              ¡QUIERO REFERIR!
            </a>
          </Row>
        </Container>

        <footer className="footer mt-5">
          <div className="footer-content">
            <div className="footer-section">
              <img src={storeIcon} alt="Almacenamos" />
              <p>ALMACENAMOS</p>
            </div>
            <div className="footer-section">
              <img src={assemblyIcon} alt="Armamos" />
              <p>ARMAMOS</p>
            </div>
            <div className="footer-section">
              <img src={deliveryIcon} alt="Despachamos" />
              <p>DESPACHAMOS</p>
            </div>
          </div>
        </footer>
        <section className="footer-social-section">
          <div className="footer-social">
            <a
              href="https://www.instagram.com/wareclouds/?hl=es"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={instagramIcon} alt="Instagram" />
            </a>
            <a
              href="https://es.linkedin.com/company/wareclouds"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={linkedinIcon} alt="LinkedIn" />
            </a>
          </div>
        </section>
        <style jsx>{`
          .footer {
            background-image: url(${backgroundFooter});
            background-size: cover;
            padding: 20px 0;
            text-align: center;
            color: white;
          }
        `}</style>
      </div>
    );
  }
};

export default ConsumerTracking;
